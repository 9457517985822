import {Navigation, Swiper} from "swiper"

document.addEventListener("DOMContentLoaded", function () {

    const swiper1 = new Swiper(".promo", {
        navigation: {
            enabled: true,
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 2.1,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 15,
            }
        },
        modules: [
            Navigation
        ]
    });

    const reviewsSlider = new Swiper(".reviews-inner", {
        navigation: {
            enabled: true,
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 2.1,
                spaceBetween: 10,

            },
            768: {
                slidesPerView: 1,
                spaceBetween: 15,
            }
        },
        modules: [
            Navigation
        ]
    });

    const secondSlider = new Swiper(".second-slider", {
        navigation: {
            enabled: true,
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 2.1,
                spaceBetween: 10,

            },
            768: {
                slidesPerView: 1,
                spaceBetween: 15,
            }
        },
        modules: [
            Navigation
        ]
    });

    const vacancies = document.querySelectorAll(".main-vacancy-block");

    vacancies.forEach(elem => {
        elem.addEventListener("click", (event) => {
            const accTitleTarget = elem.getAttribute("data-acc");

            vacancies.forEach(item => item.classList.remove("_active"));

            elem.classList.add("_active");

            document.getElementById(accTitleTarget).classList.toggle("_hide")
        })
    });

    const countdown = document.querySelector('.countdown');
    const targetDate = new Date('2023-12-26T00:00:00');

    function updateCountdown() {
        const now = new Date();
        const remainingTime = targetDate - now;

        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        document.getElementById('days').innerText = days.toString().padStart(2, '0');
        document.getElementById('hours').innerText = hours.toString().padStart(2, '0');
        document.getElementById('minutes').innerText = minutes.toString().padStart(2, '0');
        document.getElementById('seconds').innerText = seconds.toString().padStart(2, '0');
    }

// Обновляем счетчик каждую секунду
    setInterval(updateCountdown, 1000);

    (function () {
        const formItem = document.querySelectorAll(".form-box__item");
        const form = document.querySelector(".form")
        const onClick = (target) => {
            const activeId = target.dataset.id
            const activeTopBlock = [...formItem].filter(block => block.dataset.id === activeId)[0]
            formItem.forEach(block => {
                block.classList.remove("active")
            })
            activeTopBlock.classList.add("active")

            if (activeTopBlock.dataset.id === "3") {
                form.classList.add("active-select")
            } else {
                form.classList.remove("active-select")
            }
        }

        formItem.forEach(block => block.addEventListener('click', () => onClick(block)));
    })();

    document.querySelector('.header-list-box-btn').addEventListener("click", () => {
        document.querySelector('.header-list').classList.add('active')
        document.querySelector('.header-list-box-btn').classList.add('active')
        document.querySelector('.close').classList.add('active')
    })

    document.querySelector('#close').addEventListener("click", () => {
        document.querySelector('.header-list').classList.remove('active')
        document.querySelector('.close').classList.remove('active')
        document.querySelector('.header-list-box-btn').classList.remove('active')
    })

    document.querySelectorAll('.header-list-item').forEach(link => {
        link.addEventListener("click", () => {
            document.querySelector('.header-list').classList.remove('active')
            document.querySelector('.close').classList.remove('active')
            document.querySelector('.header-list-box-btn').classList.remove('active')
        })
    });

    (function () {
        const activeSelectName1 = document.querySelector('.name1')
        const activeSelectName2 = document.querySelector('.name2')
        const activeSelectName3 = document.querySelector('.name3')
        const activeSelectName4 = document.querySelector('.name4')
        const activeSelectName5 = document.querySelector('.name5')
        const formItem = document.querySelectorAll(".form-box-men");
        const onClickSelect = (target) => {
            const activeId = target.value
            const activeTopBlock = [...formItem].filter(block => block.value === activeId)[0]
            formItem.forEach(block => {
                block.classList.remove("active")
            })
            activeTopBlock.classList.add("active")

            console.log(document.querySelector('#number'))

            const activeSelect = document.querySelector('.form-box-men.active')

            if (activeSelect.value === "1") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.remove("active")
                activeSelectName3.classList.remove("active")
                activeSelectName4.classList.remove("active")
                activeSelectName5.classList.remove("active")
            } else if (activeSelect.value === "2") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.add("active")
                activeSelectName3.classList.remove("active")
                activeSelectName4.classList.remove("active")
                activeSelectName5.classList.remove("active")
            } else if (activeSelect.value === "3") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.add("active")
                activeSelectName3.classList.add("active")
                activeSelectName4.classList.remove("active")
                activeSelectName5.classList.remove("active")
            } else if (activeSelect.value === "4") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.add("active")
                activeSelectName3.classList.add("active")
                activeSelectName4.classList.add("active")
                activeSelectName5.classList.remove("active")
            } else if (activeSelect.value === "5") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.add("active")
                activeSelectName3.classList.add("active")
                activeSelectName4.classList.add("active")
                activeSelectName5.classList.add("active")
            }
        }

        const selected = document.querySelector("#number")
        formItem.forEach(block => block.addEventListener('click', () => onClickSelect(block)));
        selected.addEventListener("change", () => {
            if (selected.value === "1") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.remove("active")
                activeSelectName3.classList.remove("active")
                activeSelectName4.classList.remove("active")
                activeSelectName5.classList.remove("active")
            } else if (selected.value === "2") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.add("active")
                activeSelectName3.classList.remove("active")
                activeSelectName4.classList.remove("active")
                activeSelectName5.classList.remove("active")
            } else if (selected.value === "3") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.add("active")
                activeSelectName3.classList.add("active")
                activeSelectName4.classList.remove("active")
                activeSelectName5.classList.remove("active")
            } else if (selected.value === "4") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.add("active")
                activeSelectName3.classList.add("active")
                activeSelectName4.classList.add("active")
                activeSelectName5.classList.remove("active")
            } else if (selected.value === "5") {
                activeSelectName1.classList.add("active")
                activeSelectName2.classList.add("active")
                activeSelectName3.classList.add("active")
                activeSelectName4.classList.add("active")
                activeSelectName5.classList.add("active")
            }
        })
    })();

    document.querySelector('.form-box-input').addEventListener("click", (elem) => {
        console.log(elem)
    })
})
